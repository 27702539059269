import React from "react"
import Button from "../../Button/Button"

export default function DrawerToggle(props) {

  return(
    <Button
      onClick={ () => props.toggleDrawer()}
      style={props.style}
    >
      <i style={{margin: '0px 3px'}}className="fas fa-bars fa-lg"></i>
    </Button>
  )
}