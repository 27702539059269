import React from "react"
import styles from "./WrapperForFlex.module.css"

export default function Wrapper(props){

  return (
    <div 
    className={`${styles.wrapper} ${props.type === "card" ? styles.grey : ""}`}>
      {props.children}
    </div>
  )
}