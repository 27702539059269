// import React from "react"
import { useMediaQuery } from "react-responsive"

const useResponsive = () => {
  // const isDesktopOrLaptop = useMediaQuery({
  //   query: "(min-device-width: 1224px)",
  // })
  const isBigScreen = useMediaQuery({ query: "(min-width: 920px)" })
  const isSmallScreen = useMediaQuery({ query: "(max-width: 505px)" })
  const isTinyScreen = useMediaQuery({ query: "(max-width: 378px)" })

  // const isTabletOrMobileDevice = useMediaQuery({
  //   query: "(max-device-width: 1224px)",
  // })
  // const isPortrait = useMediaQuery({ query: "(orientation: portrait)" })
  // const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" })

  return { isBigScreen, isSmallScreen , isTinyScreen }
}

export default useResponsive;