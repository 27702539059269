import React from "react";
import { Link } from "gatsby";

import styles from "./NavLink.module.css"

const isPartiallyActive = ({ isPartiallyCurrent }) => {
  return isPartiallyCurrent
    ? { className: `${styles.active}` }
    : { className: `${styles.header}` }
}

export default function NavLink(props){

  return (
    <div className={`${styles.tabGroup}`}>
      <Link getProps={isPartiallyActive} to={props.to}>
        <div>
          {props.children}
        </div>
      </Link>
    </div>
  )
};