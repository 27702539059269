import React from "react"
import styles from "./Footer.module.css"
import { Link, navigate } from "gatsby"
import Container from "../Containers/Container"
import { logout } from "../../utils/auth"

export default function Footer() {
    return(
      <footer className={styles.footer}>
        <Container>
          <Link className={styles.link} to="./app/about">About</Link>
          <Link className={styles.link} to="./app/contact">Contact</Link>
          <Link className={styles.link} to="./app/terms-of-use">Terms of Use</Link>
          <Link className={styles.link} to="./app/categories">Categories</Link>
          <Link className={styles.link} to="./app/tags">Tags</Link>
          <Link
            className={styles.link}
            to="/"
            onClick={event => {
              event.preventDefault()
              logout(() => navigate(`/app/login`))
            }}
          >
            Log Out
            </Link>
          <div className={styles.copyright}>
            © {new Date().getFullYear()}
          </div>
          <div className={styles.attribution}>
            Favicon by{" "}
            <a
              href="https://www.flaticon.com/authors/pixel-buddha"
              title="Pixel Buddha"
            >
              Pixel Buddha
            </a>{" "}
            from{" "}
            <a href="https://www.flaticon.com/" title="Flaticon">
              www.flaticon.com
            </a>
          </div>
        </Container>
      </footer>
    )
}