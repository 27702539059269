import { useRef, useState, useEffect } from "react"

export function useOutsideAlerter() {

  const wrapperRef = useRef(null);
  const [isOutside, setIsOutside] = useState(true);

  const handleClickOutside = (event) => {
  
    if(event.target) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsOutside(true);
    }
    else if (wrapperRef.current && wrapperRef.current.contains(event.target)) {
      setIsOutside(false);
    }
    }
  }

  useEffect(() => {
    //bind the event listener
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      //unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside)
    };
  }, [wrapperRef]);

  return { isOutside, wrapperRef }
}

export default useOutsideAlerter;
