import React from "react"
import algoliasearch from "algoliasearch/lite"
import { InstantSearch, SearchBox, Hits, PoweredBy } from "react-instantsearch-dom"
import SearchHits from "./SearchHits"
import useOutsideAlerter from "../../hooks/useDetectClickOutside"
import useResponsive from "../../hooks/useResponsive"
import styles from "./Search.module.css"

//for if you have more than one index on Algolia that you are searching from
// const searchIndices = [
//   { name: `Lessons`, title: `Lessons`, hitComp: `LessonHit` },
// ]

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

const Search = ({ isOpen, toggleDrawer, isSticky }) => {

  const { isOutside, wrapperRef } = useOutsideAlerter();
  const { isSmallScreen } = useResponsive();

  return (
  <div className={styles.searchWrapper} ref={wrapperRef}>
  <InstantSearch 
    searchClient={searchClient} 
    indexName="Lessons">
    <SearchBox 
      onClick={isOpen ? () => toggleDrawer() : ""}
    />
    { isOutside === false ? 
    <div className={`
        ${styles.hitsWrapper} 
        ${isSmallScreen ? styles.mobile : {}}
        ${isSmallScreen && isSticky ? styles.sticky : {}}
      `}>
      <Hits hitComponent={SearchHits}/>
      <PoweredBy />
    </div>
    : ""
    }
  </InstantSearch>
  </div>

)}

export default Search
