import React from "react"
import NavLink from "./NavLink/NavLink"
import styles from "./NavBar.module.css"
import { Link } from "gatsby"
import DrawerToggle from "./DrawerToggle"
import Container from "../../Containers/Container"
import Search from "../../Search/Search"
import Button from "../../Button/Button"

import useResponsive from "../../../hooks/useResponsive"

// const searchIndices = [
//   { name: `Lessons`, title: `Lessons`, hitComp: `LessonHit` },
// ]

export default function NavBar(props){

  const { isBigScreen, isTinyScreen } = useResponsive()

  return (
    <>
      <div className={` ${props.sticky ? styles.stickyBackground : ""}`}>
        <Container>
          {props.sticky ? (
            <>
              <div className={styles.wrapper}>
                <div className={styles.sticky}>
                  <div className={styles.flexWrapper}>
                    <div>
                      {!isBigScreen ? (
                        <DrawerToggle
                          toggleDrawer={props.toggleDrawer}
                          isOpen={props.isOpen}
                          style="header"
                        />
                      ) : 
                        ""
                      }
                      {!isTinyScreen ? (
                        <Button style="header">
                          <Link className={styles.nonActive} to="/">
                            <i class="fas fa-home fa-lg"></i>
                          </Link>
                        </Button>
                      ) : 
                        ""
                      }
                    </div>
                    {isBigScreen ? (
                      <>
                        <div>
                          <NavLink to="/app/categories/grammar">
                            Grammar
                          </NavLink>
                          <NavLink to="/app/categories/vocabulary">
                            Vocabulary
                          </NavLink>
                          <NavLink to="/app/categories/pronunciation">
                            Pronunciation
                          </NavLink>
                        </div>
                      </>
                    ) : 
                      ""
                    }
                    <Search
                      toggleDrawer={props.toggleDrawer}
                      isOpen={props.isOpen}
                      isSticky={props.sticky}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={styles.flexWrapper}>
                <div>
                  {!isBigScreen ? (
                    <DrawerToggle
                      toggleDrawer={props.toggleDrawer}
                      isOpen={props.isOpen}
                      style="header"
                    />
                  ) : 
                    ""
                  }
                  {!isTinyScreen ? (
                    <Button style="header">
                      <Link className={styles.nonActive} to="/">
                        <i class="fas fa-home fa-lg"></i>
                      </Link>
                    </Button>
                  ) : 
                    ""
                  }
                </div>
                {isBigScreen ? (
                  <>
                    <div>
                      <NavLink to="/app/categories/grammar">Grammar</NavLink>
                      <NavLink to="/app/categories/vocabulary">
                        Vocabulary
                      </NavLink>
                      <NavLink to="/app/categories/pronunciation">
                        Pronunciation
                      </NavLink>
                    </div>
                  </>
                ) : 
                  ""
                }
                <Search
                  toggleDrawer={props.toggleDrawer}
                  isOpen={props.isOpen}
                />
              </div>
            </>
          )}
        </Container>
      </div>
    </>
  )
}