import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import styles from "./DrawerContents.module.css"
import he from "he"

export default function DrawerContents(props){


  const data = useStaticQuery(graphql`
  query {
    allWordpressCategory(filter: {slug: {nin: ["announcements", "uncategorized"]}}) {
      edges {
        node {
          id
          name
          wordpress_id
          slug
        }
      }
    }
    allWordpressPost(sort: {fields: date, order: DESC}){
      edges {
        node {
          categories {
            name
            slug
          }
          content
          date(formatString: "MMM DD, YYYY")
          title
          slug
        }
      }
    }
  }
`
  )

  const categories = data.allWordpressCategory.edges
  const lessons = data.allWordpressPost.edges

  const getAllCategories = () => {
    const allCategories = categories.map(category => category.node.wordpress_id)
    return allCategories
  }

  function getLessons(){

    const isPartiallyActive = ({ isPartiallyCurrent }) => {
      return isPartiallyCurrent
        ? { className: `${styles.active}` }
        : { className: `${styles.categoryLink}` }
    }

    
    let sortedLessons = categories.map(category =>
      lessons.filter(lesson =>
        //if lesson has multiple categories, reduce into one array
        //then only include lessons that have a matching category name to the current category
        lesson.node.categories.reduce((preCategories, category) => {
          const output = [...preCategories, category.name]
          return output
        }, []).includes(category.node.name)
        )
        )
        
        let isCollapsed = props.isCollapsed

    return categories.map((category, i) => (
      <>
        <div className={styles.categoryContainer}>
          <div
            className={styles.button}
            onClick={() => props.collapse(category.node.wordpress_id, getAllCategories())}>
            {!isCollapsed.includes(category.node.wordpress_id)
            ? <i class="fas fa-angle-down"></i>
            : <i class="fas fa-angle-up"></i>
            }
          </div>
            <Link getProps={isPartiallyActive} activeClassName="active" to={`/app/categories/${category.node.slug}`}>
              <div
                className={styles.category}
              >
              {category.node.name}
              </div>
            </Link>
        </div>

        <div className={styles.lessonWrapper}>
        {sortedLessons[i].map(lessonInCategory => (
            <>
            <Link 
              getProps={isPartiallyActive}
              to={`/app/categories/${lessonInCategory.node.categories[0].slug}/${lessonInCategory.node.slug}`}>
              <div
                className={
                  !isCollapsed.includes(category.node.wordpress_id)
                    ? styles.hidden
                    : styles.lesson
                  }
                cat={category.node.wordpress_id}
              >
                {he.decode(lessonInCategory.node.title)}
              </div>
            </Link>
          </>
        ))}
        </div>
      </>
    ))
  }

  return (
    <>
    <div
      className={styles.collapse}
      onClick={() => props.collapse("expander", getAllCategories())}
    >
        {getAllCategories().length === props.isCollapsed.length 
          ? <><i className={`${styles.icon} fas fa-book`}></i> Collapse All</> 
          : <><i className={`${styles.icon} fas fa-book-open`}></i> Expand All </>}
      </div>
    {getLessons()}
    </>
  )
}

