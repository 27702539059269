import { Link, useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import Container from "../Containers/Container"
import styles from "./Header.module.css"
import NavBar from "./NavBar/NavBar"

export default function Header(props) {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  
  return (
  <header className={styles.wrapper}>
    <Container>
      <h1 className={styles.title}>
        <Link
          to="/"
          className={styles.link}
        >
          {data.site.siteMetadata.title}
        </Link>
      </h1>
    </Container>
    <NavBar 
      sticky={props.sticky}
      toggleDrawer={props.toggleDrawer}
      isOpen={props.isOpen}
      title={data.site.siteMetadata.title}
    />
  </header>
)}



Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}
