import React from "react"
import styles from "./Button.module.css"

const Button = ({children, onClick, spacing, style}) => (
  <div 
  onClick={onClick} 
  className=
  {`
  ${styles.buttonWrapper} 
  ${style === "header" ? styles.header : ""}
  `} 
  style={spacing}>
    <div className={styles.inner}>{children}</div>
  </div>
)

export default Button