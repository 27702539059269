import React from "react"
import { Link } from "gatsby"
import { Highlight, Snippet } from "react-instantsearch-dom"
import styles from "./SearchHits.module.css"

const SearchHit = ({ hit }) => {

  const dataAsString = JSON.stringify(hit);
  const cleanData = dataAsString.replace(/&nbsp;/g, " ")
  const cleanHit = JSON.parse(cleanData)

  return (
    <div className={styles.wrapper}>
      <Link className={styles.link} to={`app/categories/${cleanHit.categories[0].slug}/${cleanHit.slug}`}>
        <h5 className={styles.title}><Highlight attribute="title" hit={cleanHit} tagName="mark" /></h5>
      <p>
        <Snippet 
        attribute={
          cleanHit._snippetResult.excerpt.matchLevel === "partial" || "full" ? `excerpt` : `content`
          } hit={cleanHit} tagName="mark" />
      </p>
      </Link>
    </div>
  )
}

export default SearchHit