import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import favicon from '../images/favicon.ico'
import { Helmet } from "react-helmet"

import "typeface-roboto-slab"
import "typeface-lato"

import Header from "./Header/Header"
import Footer from "./Footer/Footer"
import Drawer from "./Drawer/Drawer"
import Wrapper from "./Containers/WrapperForFlex"
import Container from "./Containers/Container"

import useSticky from "../hooks/useSticky"
import useResponsive from "../hooks/useResponsive"
// import useIsOpen from "../hooks/useIsOpen"

import "./layout.css"

const Layout = (props) => {

  const { isBigScreen } = useResponsive();

  //track array of which categories are collapsed
  const [isCollapsed, setIsCollapsed] = useState([]);

  const collapse = function (current, allCategories) {
    //get value of category clicked from event, value = category wordpress_ID
    // const current = e.target.value;
    if (current === "expander" && allCategories.length !== isCollapsed.length){
      setIsCollapsed([...allCategories])
    } else if (current === "expander" && allCategories.length === isCollapsed.length){
      setIsCollapsed([])
    } else {
    //get access to previous array
    setIsCollapsed((preValues) => {
      //remove category from active array if already in array
      if (preValues.includes(current)) {
        return preValues.filter((category) => {
          return category !== current;
        })
        //add currently clicked clicked category to array if not in array
      } else {
        return [...preValues, current]
      }
    })
  }
  }

  //persist the state of the active categories by storing in local storage
  useEffect(() => {
    const data = localStorage.getItem("active-array");
    if (data){
      setIsCollapsed(JSON.parse(data));
    }
  }, [])
  
  useEffect(() => {
    localStorage.setItem("active-array", JSON.stringify(isCollapsed));
  })


  // use function for sticky refs
  const { isSticky, element } = useSticky()

  //drawer control
  const [isOpen, setIsOpen] = useState(false)
  
  if (isOpen === false && isBigScreen) {
    setIsOpen(true)
  }    

  // const closeDrawer = () => setIsOpen(false)
  const toggleDrawer = () => setIsOpen(!isOpen);
  
  return (
    <>
      <Helmet>
        <link rel="icon" href={favicon} />
        <title>LG Lessons</title>
        <script src="https://unpkg.com/masonry-layout@4/dist/masonry.pkgd.min.js"></script>
        <script
          src="https://kit.fontawesome.com/e249fab848.js"
          crossorigin="anonymous"
        ></script>
      </Helmet>
      <div className="wrapper" ref={element}>
        <Header
          toggleDrawer={toggleDrawer}
          isOpen={isOpen}
          sticky={isSticky}
          useResponsive={useResponsive}
        />
        <div className="rowWrapper">
          <div className="makeFlexboxHappy">
          <Drawer
            isOpen={isOpen}
            sticky={isSticky}
            collapse={collapse}
            isCollapsed={isCollapsed}
            useResponsive={useResponsive}
          />
          {isBigScreen ? (
            isSticky ? (
              <div className="drawerPlaceholder" />
            ) : (
              ""
            )
          ) : (
            <div></div>
          )}
          </div>
          <div className="columnWrapper">
            <Wrapper type={props.type}>
              <Container>
                <div className="verticalContainer">
                  {isSticky ? <div className="headerPlaceholder" /> : ""}
                  <main>{props.children}</main>
                </div>
              </Container>
            </Wrapper>
            <Footer />
          </div>
        </div>
      </div>
    </>
  )
}


Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
