import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../../layout"
import Announcements from "../../Announcements/Announcements"
import Button from "../../Button/Button"
import MyLink from "../../MyLink/MyLink"
import { YoutubeSearchedFor } from "@material-ui/icons"
const HomePage = () => {
  const data = useStaticQuery(graphql`
  query {
    wordpressPage(wordpress_id: {eq: 5}) {
      content
      title
      wordpress_id
      slug
    } 
    allWordpressPost(sort: {fields: date, order: DESC}, filter: {date: {}, categories: {elemMatch: {name: {eq: "Presentations"}}}}) {
    nodes {
      tags {
        name
        slug
      }
    }
  }  
  }
`
  )

  const homepage = data.wordpressPage.content
  
  const tagsOnFirstPresentation = data.allWordpressPost.nodes[0].tags
  const date = new Date();
  const year = date.getFullYear();
  const dateTag = tagsOnFirstPresentation.filter(tag => tag.slug.indexOf(year) !== -1 || tag.slug.indexOf(2020) !== -1);

  return (
    <>
      <Layout type="text">
        <div dangerouslySetInnerHTML={{ __html: homepage }} />
        <Button>
          <MyLink to={`/app/tags/${dateTag[0].slug}`}><p>Go to latest presentations →</p></MyLink>
        </Button>
        <Announcements />
      </Layout>
    </>
  )
}

export default HomePage