import React from "react"
import styles from "./Drawer.module.css"
import DrawerContents from "./DrawerContents"

// import useOutsideAlerter from "../../hooks/useDetectClickOutside"
import useResponsive from "../../hooks/useResponsive"

export default function Drawer(props){

  const { isBigScreen } = useResponsive();
  // const { isOutside, wrapperRef } = useOutsideAlerter()
  // const closeDrawer = props.closeDrawer

  
  // if (props.isOpen === true && !isBigScreen && isOutside)  {
  //   console.log("you clicked outside")
  //   return closeDrawer
  // } 

  return (
    <>
      <div>
        {!props.sticky ? (
          <div
            className={`
        ${styles.container} 
        ${props.isOpen ? styles.containerOpen : ""}
        ${!isBigScreen ? styles.floating : ""}
        `}
          >
            <DrawerContents
              collapse={props.collapse}
              isCollapsed={props.isCollapsed}
            />
          </div>
        ) : (
          <>
            <div
              className={`
          ${styles.container} 
          ${props.isOpen ? styles.containerOpen : ""} 
          ${!isBigScreen ? styles.floating : ""}
          ${styles.sticky}
          `}
            >
              <DrawerContents
                collapse={props.collapse}
                isCollapsed={props.isCollapsed}
              />
            </div>
          </>
        )}
      </div>
    </>
  )
}