import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import styles from "./Announcements.module.css"

export default function Announcements () {
  const data = useStaticQuery(graphql`
     query {
       allWordpressPost(filter: {categories: {elemMatch: {name: {eq: "Announcements"}}}}, limit: 3, sort: {order: DESC, fields: date}) {
         edges {
           node {
             wordpress_id
             title
             content
             date(formatString: "MMMM DD, YYYY")
           }
         }  
       }
     }
 `)

  const dataAsString = JSON.stringify(data.allWordpressPost.edges);
  const cleanData = dataAsString.replace(/&nbsp;/g, " ")
  const dataAsObject = JSON.parse(cleanData)

 return (
    <div className={styles.card}>
      <h2>Announcements</h2>
      {dataAsObject.map( announcement =>
       {
         return (
           <div>
             <h3>{announcement.node.title}</h3>
             <p>{announcement.node.date}</p>
             <div dangerouslySetInnerHTML={{ __html: announcement.node.content}} />
           </div>
         )})}
    </div>
 )
}